<template lang="pug">
.form
  .form__wrapper(v-if="!send")
    .form__fields
      .form__field
        .input-text
          input.input-text__input(type="text" placeholder="Ваше имя" v-model="name")
      .form__field
        .input-text
          input.input-text__input(type="tel" placeholder="Ваш телефон *" v-model="phone" v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']")
      .form__field
        .input-textarea
          textarea.input-textarea__input(placeholder="Ваше сообщение" v-model="message")
    .form__files
      .form-files
        .form-files__wrapper
          .form-files__text
            .form-files__title(v-html="fileTitle")
            .form-files__description(v-html="fileDescription")
          .form-files__list
            .form-files__item
              input(
                type="file"
                name="files"
                ref="files"
                id="files"
                multiple
                style="display: none;"
                @change="onFileChange"
              )
              label.form-files__label(
                v-html="fileLabel"
                for="files"
              )
            .form-files__item
              label.form-files__result(v-html="fileResult")
    .form__consent
      .consent
        .consent__wrapper
          input.consent__input(type="checkbox" id="popup-form" checked="checked")
          label.consent__label(
            for="popup-form"
          ) Я согласен с <a :href="linkPolit">политикой конфиденциальности</a> персональных данных и даю свое <a :href="linkSogl">согласие на их обработку</a>
    .form__button
      .form__button-wrapper
        .button.button-yellow(@click="getSend()" :class="{'button-disable': v$.$invalid}")
          .button__wrapper
            .button__text Отправить
  .form__success(v-else)
    .form-success
      .form-success__wrapper
        .form-success
          p Ваше сообщение отравлено.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'PopupForm',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      name: '',
      phone: '',
      message: '',
      send: false,
      fileTitle: 'Прикрепите файл резюме:',
      fileDescription: '(можно прикрепить несколько файлов)',
      fileLabel: 'Обзор',
      fileResult: 'Файл не выбран',
      files: '',
      classButtonText: '',
      linkPolit: '/politika-konfidenczialnosti.html',
      linkSogl: '/polzovatelskoye-soglashenie.html'
    }
  },

  methods: {
    ...mapActions([
        'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Свяжитесь с нами"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.name
            },

            {
              title: 'Телефон',
              value: this.phone
            },

            {
              title: 'Сообщение',
              value: this.message
            }
          ],
          files: this.files
        })

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return { phone: { required, minLength: minLength(18), maxLength: maxLength(18) } }
  }
}
</script>
